export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * full url, no username
   */
  discord?: string;
  /**
   * full url, no username
   */
  instagram?: string;
  /**
   * full url, no username
   */
  kickstarter?: string;  
  /**
   * steam redirect
   */
  steam?: string;
  /**
   * ps4 redirect
   */
  ps4?: string;
  /**
   * ps5 redirect
   */
  ps5?: string;
  /**
   * nx redirect
   */
  nx?: string;
  /**
   * xbox redirect
   */
  xbox?: string;
  /**
   * preorder
   */
  preorder?: string
  /**
   * full url, no username
   */
  youtubeId?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;

  mailchimpLanding?: string;

  demoFestival?: string;
  
  demoCallToAction?: boolean;

  outOnSteam?: boolean;

  functionsURL: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'Roots of Pacha',
  description: 'A co-op farming & life simulation game set in the stone age. Sow your way in the early days of civilization.',
  coverImage: 'img/logo.png',
  logo: 'img/whats-roots-of-pacha/key-art-1.png',
  lang: 'en',
  siteUrl: 'https://roots-of-pacha-test.web.app',
  facebook: '',
  twitter: 'https://twitter.com/rootsofpacha',
  kickstarter: 'https://www.kickstarter.com/projects/sodaden/roots-of-pacha',
  discord: '/discord',
  instagram: 'https://www.instagram.com/roots_of_pacha/',
  steam: '/steam',
  ps4: '/ps',
  ps5: '/ps',
  nx: '/nx',
  xbox: '/xbox',
  preorder: '/preorder',
  youtubeId: 'mYEhKvcDhsY',
  showSubscribe: false,
  demoFestival: '/festival',
  demoCallToAction: false,
  outOnSteam: true,
  mailchimpAction: '',
  mailchimpName: '',
  mailchimpEmailFieldName: 'EMAIL',
  mailchimpLanding: 'https://mailchi.mp/crytivo/rootsofpacha',
  googleSiteVerification: 'GoogleCode',
  functionsURL: 'https://us-central1-roots-of-pacha-pledgebox.cloudfunctions.net', // Prod
  //functionsURL: 'http://localhost:5000/roots-of-pacha-pledgebox/us-central1', // Dev
  footer: '',
};

export default config;
