import "../styles/mui.scss";
import "../styles/globals.css"

import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { scroller } from "react-scroll";
import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { OutlineButton } from "./OutlineButton";
import config from "../website-config";
import { TagCss } from "./styles";
import { colors } from "../styles/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faSteam } from "@fortawesome/free-brands-svg-icons";

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: center;
  align-items: 'center';
`

const LogoContainerCss = css`
  margin-top: 24px;
  margin-bottom: 0px;
`

const LogoCss = css`
  width: 100%;
  max-width: 496px;
  min-height: auto;
  cursor: pointer;
`

export const PreorderClosed: React.FC = () => {
  return <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "img/logo.png" }) {
          publicURL
        }
      }
  `}
    render={data => {

      return <HeaderDiv>
        <div css={[LogoContainerCss]}>
          <a href={"/"}>
            <img draggable={false} css={[LogoCss]} src={data.logo.publicURL} />
          </a>
        </div>
        <div css={[TagCss]} style={{ textAlign: 'center', color: colors.logoPurple }}>
          Preorder store is now closed.
        </div>
        <div css={css`
          font-family: 'LondrinaSolid';
          font-size: 1.4em;
          margin: 32px 0 0;
          text-align: center;
        `}>
          Join us in this journey:
        </div>
        {
          config.steam && <React.Fragment>
            <OutlineButton style={{ marginTop: 16 }} href={config.steam}>
              <FontAwesomeIcon size="lg" icon={faSteam} style={{ margin: '0px 16px' }} />
              Wishlist now!
            </OutlineButton>
          </React.Fragment>
        }
        {
          config.discord && <OutlineButton style={{ marginTop: 16 }} href={config.discord}>
            <FontAwesomeIcon size="lg" icon={faDiscord} style={{ margin: '0px 16px' }} />
            Join our community!
          </OutlineButton>
        }
      </HeaderDiv>
    }} />
}
