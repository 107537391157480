import { css } from "@emotion/core";

export { inner as InnerCSS } from "../styles/shared";

export const TagCss = css`
  text-align: center;
  font-size: 2em;
  line-height: 0.9;
  color: #fafafa;
  font-family: 'LondrinaSolid';
  letter-spacing: 2px;
  margin: 0px;
`