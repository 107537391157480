import "../styles/mui.scss";

import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import { outer, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import Footer from '../components/Footer';
import "react-image-gallery/styles/scss/image-gallery.scss";

import { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { SiteHelmet } from "../components/Helmet";

import { InfoSection } from "../components/InfoSection";

import VideoHeader from "../components/VideoHeader";
import FixedBar from "../components/FixedBar";
import { GameDescription } from "../components/GameDescription";
import { scroller } from "react-scroll";
import { PreorderClosed } from "../components/PreorderClosed";

const WrapperCss = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PageTemplate = css`
  .site-main {
    width: 100%;
  }
`;


type Props = {
    data: any
}

const Landing: React.FC<Props> = () => {
    const useFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = () => {
            htmlElRef.current && (htmlElRef.current! as any).controlEl.focus()
        }

        return [htmlElRef, setFocus]
    }

    const [setNewsletterInputFocus] = useFocus()

    useEffect(() => {
        if (location.hash.indexOf('newsletter') > -1) {
            (setNewsletterInputFocus as () => void)();
        } else if (location.hash.indexOf('challenge') != -1) {
            scroller.scrollTo("challenge", {
                duration: 500,
                delay: 500,
                smooth: true,
                offset: -80,
            });
        }
    });

    return <IndexLayout>
        <SiteHelmet />
        <Wrapper css={[PageTemplate, WrapperCss]}>
            <main id="site-main" className="site-main" css={[SiteMain, outer]}>
                <div style={{ position: 'absolute', top: 24, bottom: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PreorderClosed />
                </div>
            </main>
        </Wrapper>
    </IndexLayout >
}

export default Landing;
