export const colors = {
  blue: '#3eb0ef',
  green: '#a4d037',
  purple: '#ad26b4',
  yellow: '#fecd35',
  red: '#f05230',
  darkgrey: '#15171A',
  midgrey: '#738a94',
  lightgrey: '#c5d2d9',
  whitegrey: '#e5eff5',
  white: '#fff',
  pink: '#fa3a57',
  brown: '#a3821a',

  ksGreen: '#05cd78',

  cavebrown: '#6c4a4f',
  cavedarkbrown: '#21181b',
  caveorange: '#be5739',
  cavelinks: '#e65100',
  cavefooter: '#914050',
  cavemain: '#fbdcbd',
  cavebutton: '#823947',
  cavebuttonhighlight: '#914050',
  
  logoPurple: '#75232a',
  logoLightPurple: '#c7bbd3',
  logoOrange: '#da744b',
  logoComplementary: '#4ac8d3',
};
