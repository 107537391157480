import { css } from "@emotion/core"

type Props = {
  href: string,
  buttonStyleProps?: ButtonStyleProps
  style?: React.CSSProperties
  noBlank?: boolean
  noAnchor?: boolean
}

export type ButtonStyleProps = {
  baseColor: string,
  outlineColor?: string,
  baseColorHover?: string,
}

export const anchorCSS = css`
  text-decoration: 'none';
  cursor: pointer;
  :active, :visited, :hover { 
    text-decoration: none; 
  }
`

const buttonCSS = (buttonStyle: ButtonStyleProps) => css`
padding: 4px 16px;
height: 40px;
background-color: ${buttonStyle.baseColor};
border-radius: 4px;
text-transform: uppercase;
color: white;
display: flex;
align-items: center;
justify-content: center;
font-family: 'LondrinaSolid';
font-size: 1.2em;
letter-spacing: 2px;
border: solid 2px ${buttonStyle.outlineColor || buttonStyle.baseColor};
transition: background-color .25s, border .25s, color .25s;

:hover {
  background-color: ${buttonStyle.baseColorHover || '#fafafa'};
  color: ${buttonStyle.baseColor};
}

:active {
  background-color: ${buttonStyle.baseColor};
  color: ${buttonStyle.outlineColor};
}
`

const DEFAULT_BUTTON_STYLE: ButtonStyleProps = {
  baseColor: '#da744b'
}

export const OutlineButton: React.FC<Props> = ({ href, children, style, buttonStyleProps, noBlank, noAnchor }) => {

  return noAnchor
    ? <div draggable={false} css={buttonCSS(buttonStyleProps || DEFAULT_BUTTON_STYLE)} style={style}>
      {children}
    </div>
    : <a draggable={false} href={href} css={anchorCSS} target={noBlank ? "" : "_blank"}>
      <div draggable={false} css={buttonCSS(buttonStyleProps || DEFAULT_BUTTON_STYLE)} style={style}>
        {children}
      </div>
    </a>
}