import { StaticQuery, graphql } from "gatsby";
import config from "../website-config";
import Helmet from "react-helmet";

type Props = {
  header?: any
  title?: string
  description?: string
  url?: string
  publishedTime?: string
  tag?: string
  type?: string
}

export const SiteHelmet: React.FC<Props> = (props) => {

  return <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "img/logo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
        }
        header: file(relativePath: { eq: "img/website-poster.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
  `}
    render={data => {
      var header = props.header || data.header

      const width = header.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
      const height = String(Number(width) / header.childImageSharp.fluid.aspectRatio);

      return <Helmet>
        <html lang={config.lang} />
        <title>{config.title}</title>
        <meta name="description" content={props.description || config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content={props.type || "website"} />
        <meta property="og:title" content={props.title || config.title} />
        <meta property="og:description" content={props.description || config.description} />
        <meta property="og:url" content={config.siteUrl + (props.url || '')} />
        <meta
          property="og:image"
          content={`${config.siteUrl}${header.childImageSharp.fluid.src}`}
        />
        {
          props.publishedTime && <meta property="article:published_time" content={props.publishedTime} />
        }
        {props.tag && (
          <meta property="article:tag" content={props.tag} />
        )}

        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.googleSiteVerification && <meta name="google-site-verification" content={config.googleSiteVerification} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={props.title || config.title} />
        <meta name="twitter:description" content={props.description || config.description} />
        <meta name="twitter:url" content={config.siteUrl + (props.url || '')} />
        <meta
          name="twitter:image"
          content={`${config.siteUrl}${header.childImageSharp.fluid.src}`}
        />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta property="og:image:width" content={width} />
        <meta property="og:image:height" content={height} />
      </Helmet>
    }}
  />
}
